.globalList {
  padding: 50px;
  background: $light_main_color;
  .globalStatistisText{
    max-width: 1180px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 40px;
    select{
      width: fit-content;
      padding: 0;
      color: $middle_font_color;
      background: $middle_main_color;
    }
  }
}
.slider-handles{
  div:first-child{
    z-index: 6!important;
    width: 8px!important;
  }
  div:nth-child(3){
    width: 8px!important;
    padding-right: 5px;
  }
}
.button-wrapper {
  text-align: center;
  margin-top: 25px;
  padding: 25px 0;
  .leaderbord_link {
    background: none;
    border: 1px solid $dark_font_color;
    color: $dark_font_color;
    padding: 20px;
    margin-top: 25px;
    text-decoration: none;
  }
}
.topTenHeader {
  font-family: $header_font;
  font-weight: normal;
  text-align: center;
  color: $middle_font_color;
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 36px;
}
.global_list {
  font-family: $average_font;
  color: $dark_font_color;
  width: 100%;
  max-width: 1180px;
  margin: auto;
  overflow-x: auto;
  tr,
  td{
    border-collapse: collapse;

  }
  tr{
    &.subTable{
      &:nth-child(even) {
        background-color: #d9e1e6;
      }
      &:nth-child(4n+0) {
        background-color: #f2f5f6;
      }
      &.userStrategy{
        background-color: $contrast_color!important;
        .field_item{
          color: $light_main_color!important;
          span{
            color: $light_main_color!important;
          }

        }
      }
      svg{
        cursor: pointer;
        path{
          color: $light_border;
        }
      }
    }
  }
  .shrink-toggle{
    span{
      color:$light_border!important;
      path{
        color:$light_border!important;
      }
    }
  }
  td{
    padding: 0.75rem;
    vertical-align: top;
    word-break: break-all;
  }
  .subTable {
    font-size: 16px;
    .name {
      text-align: left;
      max-width: 300px;
    }
    .author {
      max-width: 200px;
      white-space: nowrap;
    }
  }
  .topTenHeader {
    margin-top: 5%;
    text-align: center;
    font-family: $average_font;
    color: $middle_font_color;
    font-size: 1.6em;
    text-transform: uppercase;
    font-weight: bold;
  }
  .template_container {
    margin: 0;
    border: none;
    background: $light_main_color;
    color: $dark_font_color;
  }
  .subTableHeader {
    border: none;
    background: $light_main_color;
    width: 100%;
    border-collapse: collapse;
    overflow: hidden;
    tr,th{
      border-collapse: collapse;
      color: $light_main_color!important;
    }
    tr {
      text-align: center;
      vertical-align: bottom;

      &.shrink-wrapper{
        background: #e7e7e7;
        td{
          padding-bottom: 0!important;
          padding-top: 0!important;
          padding-right: 10px!important;
          padding-left: 3px!important;
        }

        &.m-fadeOut {
          overflow: hidden;
          div{
            padding: 0;
            margin: 0;
            max-height: 0;
            transition: max-height 0.5s ease-out, padding 0.3s linear 0.4s;
            overflow: hidden;
          }
        }
        &.m-fadeIn {
          div{
            padding: 5px;
            overflow: hidden;
            max-height: 500px;
            transition: max-height 0.5s ease-in;

          }
        }
        td{
          background: #e7e7e7!important;
          text-align: left;
          padding: 5px 10px;
        }

      }
      th {
        background: $accent_dark;
        cursor: help;
        padding: 10px 5px;
        color: $light_main_color!important;
        font-family: $average_font;
        border: 1px solid $accent_dark;
        text-align: center;
        &.author,
        &.rank{
          cursor: default;
        }
        span{
          color: $light_main_color!important;
          text-align: center;
        }

        &.name{
          font-family: $average_font;
          text-align: left;
          padding: 10px;
          cursor: default;
          color: $light_main_color!important;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .globalStatistisText {
    margin-bottom: 20px;

  }
  .global_list{
    .subTable{
      font-size: 14px!important;
    }
  }
  .subTableHeader {
    tr{
      .author,
      .is{
        display: none;
      }
      .shrink-author,
      .shrink-is{
        display: table-row!important;
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .global_list .subTableHeader tr.shrink-wrapper{
    display: none;
  }
  .statistics_charts{
    width: 100%;
    max-width: 800px;
  }
  .statistics_subinfo{
    width: 100%;
    max-width: 400px!important;
  }
  .subTableHeader {
    tr{
      .shrink-toggle{
        display: none;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
      .subTableHeader {
        tr{
          .instruments,
          .sub_duration,
          .tr_duration,
          .max_dd,
          .holdingTime{
            display: none;
          }
          .shrink-author,
           .shrink-is{
             display: none;
           }
        }
      }
      .statisticsWrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
}



@media screen and (max-width: 480px) {
  #global-list {
    padding: 0!important;
    background: $light_main_color;
  }
  .globalList{
    padding: 20px;
  }
  .popup_inner.popup_inner_statistics{
    width: 100%!important;
    height: 100% !important;
    max-height: none!important;
    border-radius: 0!important;
    .statistics_subinfo{
      align-items: center!important;
      padding-left:0!important;
    }
  }


}