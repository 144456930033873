
.wrapper_contacts{
  padding: 50px;
  background: $middle_main_color;
}
.checkbox_wrapper{
  padding: 0!important;
  display: flex!important;
  justify-content: left!important;
  align-items: center!important;
  .checkbox-custom{
    min-width: 25px!important;
  }
}
.filter_item{
  .label{
    text-transform: none!important;
  }
}
.about_image.about_image_office {
  background: url("../img/sankt-peterburg-piter.jpg");
  background-size: cover;
}
.contacts_wrapper {
  background: $middle_main_color;
  padding-top: 60px;
  padding-bottom: 50px;
  position: relative;
  &.contacts_wrapper_main{
    padding: 0;
  }
  form{
    margin: 0;
  }
}
.section_header_contacts {
  font-family: $header_font;
  color: $middle_font_color;
  text-align: center;
  margin-bottom: 40px;
  text-transform: none;
  font-size: 36px;
  font-weight: normal;
}
.about_wrapper {
  .contactItem {
    margin: 5% 0;

    h3 {
      font-family: $average_font;
      color: $contrast_color;
      text-transform: uppercase;
      margin-bottom: 0;
    }
    p {
      padding: 0;
      font-size: 1.1em;
      margin-top: 0;
    }
  }
  .cont_info {
    font-family: $bold_font;
    font-size: 1em;
    color: $dark_font_color;
    font-weight: bold;
    a {
      &:hover {
        color: $contrast_color;
      }
    }
  }
  .soc_darkWrapper {
    padding: 3px 1px;
    display: flex;
    justify-content: left;
    align-items: center;
    a {
      text-decoration: none;
      font-family: $average_font;
      font-weight: normal;
      cursor: pointer;
      color: $middle_font_color;
      &:hover {
        color: $contrast_color;
      }
    }
  }
  .soc_dark_vk,
  .soc_dark_fb,
  .soc_dark_in,
  .soc_dark_tg,
  .soc_dark_zen {
    height: 20px;
    width: 20px;
    background-image: url("../img/soc_icons.png");
    background-repeat: no-repeat;
    display: inline-block;
    margin-right: 15px;
  }
  .soc_dark_vk {
    background-position: -143px -9px;
  }
  .soc_dark_fb {
    background-position: -176px -9px;
  }
  .soc_dark_in {
    background-position: -176px -43px;
  }
  .soc_dark_tg {
    background-position: -143px -42px;
  }
  .soc_dark_zen {
    background-position: -109px -42px;
  }
  .mainInfoWrapper {
    .emailInfo {
      font-weight: 400;
      font-size: 1.1em;
      margin-top: 0;
    }
    .addrInfo {
      font-size: 1.1em;
      font-weight: 400;
      width: 100%;
      text-align: left;
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
    }
  }
}
.teamPhoto {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: $middle_main_color;
}
.about_image.about_image_map {
  width: 100%;
  max-width: 700px;
  .imageContainer {
    background-image: url("../img/map_office_spb.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
    padding-top: 66.64%; /* (img-height / img-width * container-width) */
    /* (853 / 1280 * 100) */
  }
  background: url("../img/map_office_spb.jpg");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
}
.contactForm {
  width: 100%;
  max-width: 1180px;
  background: $middle_main_color;


  h3 {
    font-family: $average_font;
    font-weight: 700;
    font-size: 1.2em;
    color: $dark_font_color;
    margin-top: 5%;
    text-align: center;
    margin-bottom: 4%;
    width: 100%;
  }
  .teamImage {
    width: 100%;
    max-width: 500px;
    height: 500px;
    background: url("../img/ice_petersburg.jpg");
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    display: flex;
    align-items: flex-end;
    margin-right: 5%;
  }
  .about_wrapper {
    width: 100%;
    p {
      margin: 0;
      font-size: 1em;
    }
  }
}
.about_wrapper {
  &.about_wrapper_contacts{
    padding-right: 20px;
    .addressInfo {
      color: $middle_font_color;
      text-align: left;
      width: 100%;
      padding-top: 0;
      margin-top: 0;
      font-size: 21px;
      font-family: $header_font;
    }
    h6{
      text-transform: uppercase;
      font-size: 21px;
      line-height: 32px;
      margin-bottom: 10px;
    }
    p{
      font-size: 21px;
      line-height: 32px;
      margin-bottom: 10px;
    }
  }
  .addressInfoTitle {
    color: $middle_font_color !important;
    font-size: 1.2em !important;
  }

  .addressLink {
    color: $contrast_color !important;
    text-decoration: none;
    font-family: $average_font;
  }
}
.socButtonWrapper {
  width: 100%;
  display: inline-flex;
  justify-content: flex-start;
  a {
    border-radius: 50%;
    cursor: pointer;
    margin: 0 2% 2% 2%;

  }
}
.contactFormContainer {
  p.addrInfo {
    padding: 0;
    text-align: left;
    font-size: 1.1em;
    font-weight: 400;
    margin-bottom: 3%;
  }
  .contactsWrapper {
    display: flex;
    justify-content: space-between;

    section {
      width: 100%;
      input {
        width: 100%;
        padding: 2% 0;
      }
    }
  }
  .copied{
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    float: right;
    -webkit-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
    &.success{
      &::after {
        position: absolute;
        padding: 5px;
        border-radius: 5px;
        width: 150px;
        content: attr(data-tooltip);
        top: -38px;
        color: #ffffff;
        left: -20px;
        border: 2px solid #28b300;
        background: #28b300;
        font-size: 1em;
        -webkit-transition: all 1s ease 0s;
        transition: all 1s ease 0s;
        text-align: center;
      }
      &:before {
        content: " ";
        position: absolute;
        background: #28b300;
        width: 10px;
        height: 10px;
        top: -11px;
        left: 73px;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        transition: all 1s ease 0s;
      }
    }

  }
  .personaldataWrapper {
    display: flex;
    justify-content: flex-start;
    input[type="checkbox"] {
      width: 20px;
    }
    label {
      color: $middle_font_color;

      a {
        font-family: $average_font;
        font-size: 0.8em;
        color: $middle_font_color;
        text-decoration: none;
        &:hover {
          color: $contrast_color;
        }
      }
    }
  }
  form label,
  form input,
  form textarea {
    display: block;
    //margin-bottom: 10px;
    width: 100%;
  }

  form input,
  form textarea {
    font-family: $average_font;
    font-size: 1em;
    padding: 2% 1%;
    background: $light_main_color;
    border: 1px solid $light_border;
  }
  .buttonsWrapper {
    /* width: 50%; */
    /* max-width: 200px; */
    display: flex;
    /* margin-right: 5%; */
    justify-content: flex-start;
  }
  .button {
    margin-left: 2%;
    width: 150px;
    max-width: 150px !important;
    color: $light_main_color;
    text-transform: capitalize;
    padding: 15px;
    font-family: $bold_font;
    font-size: 0.9em;
    outline: none;
    background-color: $contrast_color;
    border: 1px solid $contrast_color;
    cursor: pointer;
    transition: all 300ms ease-in;
    float: right;
    &:hover {
      background-color: #fd8e3a;
      border: 1px solid #fd8e3a;
      color: $light_main_color;
    }
    &:disabled {
      cursor: default;
      background-color: $middle_main_color;
      border: 1px solid $light_border;
      color: $middle_font_color;
    }



  }

  .errorMsg {
    color: #cc0000;
    font-family: $average_font;
    font-size: 0.7em;
    margin-bottom: 12px;
  }

  .sucessMsg {
    color: #6b8e23;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .copied{
    float: none!important;
    margin: auto!important;
  }
  .contacts_wrapper{
    margin-top: 0;
    .section_header_contacts{
      font-size: 26px;
      margin: 0;
      margin-bottom: 25px;
    }
  }
  .about_container.contactForm .about_wrapper .contactFormContainer .contactForm form input, .about_container.contactForm .about_wrapper .contactFormContainer .contactForm form textarea {
    font-size: 1em!important;
    padding: 10px!important;
    box-sizing: border-box;
  }

  .about_wrapper {
    padding: 0;
    padding-top: 15px;

    &.about_wrapper_contacts{
      padding: 0;
      padding-right: 20px;
      .addressInfo {
        color: $middle_font_color;
        text-align: left;
        width: 100%;
        padding: 0!important;
        margin-top: 0;
        font-size: 16px;
        font-family: $header_font;
      }
      h6{
        display: none;
      }
      p{
        display: none;
      }
    }
    .addressInfoTitle {
      color: $middle_font_color !important;
      font-size: 1.2em !important;
    }

    .addressLink {
      color: $contrast_color !important;
      text-decoration: none;
      font-family: $average_font;
    }
  }
}
@media only screen and (max-width: 768px) {
  .wrapper_contacts{
    padding: 20px!important;
  }
}