.section_header_events {
  font-family: $header_font;
  color: $middle_font_color;
  font-size: 36px;
  margin-top: 90px;
  text-align: center;
  margin-bottom: 40px;
}
.events_list {
  padding: 0;
  padding-bottom: 5%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 1180px;
  margin: auto;
  grid-column-gap: 1em;
  grid-row-gap: 2em;

  .event_item {
    width: 100%;
    height: 100%;
    max-width: 300px;
    padding: 0 15px 15px;
    margin: 0 auto;
    overflow: hidden;
    background-color: #ffffff;

    .event_status {
      float: right;
      text-transform: uppercase;
      width: 100%;
      text-align: center;
      margin: 0;
      font-size: 1em;
      color: $dark_font_color;
    }

    p,
    h3 {
      padding: 5px 0;
    }

    span {
      padding: 0 10px;
    }
    .viewButton {
      text-align: center;
      color: $dark_main_color;
      background: $light_main_color;
      //text-transform: uppercase;
      padding: 10px;
      font-family: $average_font;
      font-weight: 400;
      font-size: 0.8em;
      outline: none;
      border-radius: 5px;
      text-decoration: none;
      border: 1px solid $dark_main_color;
      margin: 3% 0;
      width: fit-content;
      transition: all 300ms ease-in;
      &:hover {
        color: $light_main_color;
        background: $contrast_color;
        border: 1px solid $contrast_color;
      }
    }
    .event_date {
      font-family: "Merriweather", sans-serif;
      font-weight: normal;
      font-size: 0.8em;
      width: 100%;
      text-align: left;
      margin-bottom: 0;
      margin-top: 0;
      color: $dark_font_color;
    }

    .fa {
      margin-top: 10px;
      font-size: 1.2em;
      color: $contrast_color;
    }

    .city {
      color: $dark_font_color;
      font-family: $average_font;
      font-size: 1em;
      font-weight: normal;
      padding: 0;
    }

    .event_link {
      float: right;
      text-transform: uppercase;
      font-size: 1em;
    }

    .city,
    .event_link {
      margin-bottom: 10px;
    }

    .event_title_wrapper {
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding-bottom: 10px;
      width: 100%;
      background-image: linear-gradient(to top, white 90%, transparent 100%);
      z-index: 998;
      height: calc(100% - 210px);
      a {
        text-decoration: none;
        h3 {
          margin-top: 0;
        }
      }
      .eventMapLink {
        font-size: 0.8em;
        text-align: left;
        cursor: pointer;
        color: $dark_font_color;
        &:hover {
          color: $contrast_color !important;
        }
      }
    }

    .event_img_wrapper {
      height: 100%;
      figure {
        height: 188px;
        width: calc(100% - 2px);
        overflow: hidden;
        margin: 0;
        border: none;
        cursor:pointer;
      }
      &:hover{
        img{
          filter: brightness(85%);
        }
      }
      img {
        width: 100%;
      }
      h3 {
        font-family: $header_font;
        color: $middle_font_color;
        z-index: 999;
        opacity: 1;
        text-align: left;
        margin: 0;
        font-size: 16px;
      }
    }

  }
}
.filter {
  position: relative;
  margin-bottom: 70px;
  form {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $average_font;
    text-transform: uppercase;
    padding: 0 8%;
  }
  .filter_input{
    min-width: 300px;
    height: 40px;
    padding-left: 10px;
    margin: 0;
    box-sizing: border-box;
    font-size: 16px;
    font-family: $average_font;
    color: #666;
    font-weight: normal;
    border-radius: 0;
    text-transform: capitalize;
    &:hover{
      border-color: $dark_font_color;
    }
  }
}
.filter_item {
  display: block;

  label {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    font-family: $average_font;
    font-size: 1em;
  }
  select,
  input[type="text"] {
    outline: none;
    border: 1px solid $light_border;
    border-radius: 5px;
    color: $middle_font_color;
    font-family: $average_font;
    text-transform: uppercase;
  }
  select {
    min-width: 300px;
    padding: 0 20px;
  }
  .checkbox {
    display: none;
  }
  /* Задаем внешний вид для нашего кастомного чекбокса. Все обязательные свойства прокомментированы, остальные же свойства меняйте по вашему усмотрению */
  .checkbox-custom {
    position: relative; /* Обязательно задаем, чтобы мы могли абсолютным образом позиционировать псевдоэлемент внютри нашего кастомного чекбокса */
    width: 25px; /* Обязательно задаем ширину */
    height: 25px; /* Обязательно задаем высоту */
    border: 1px solid $middle_font_color;
    border-radius: 0;
  }
  /* Кастомный чекбокс и лейбл центрируем по вертикали. Если вам это не требуется, то вы можете убрать свойство vertical-align: middle из данного правила, но свойство display: inline-block обязательно должно быть */
  .checkbox-custom,
  .label {
    display: inline-block;
    vertical-align: middle;
    color: $middle_font_color;
    margin-left: 5px;
    text-transform: capitalize;
    font-family: $average_font;
  }
  /* Если реальный чекбокс у нас отмечен, то тогда добавляем данный признак и к нашему кастомному чекбоксу  */
  .checkbox:checked + .checkbox-custom::before {
    content: '';
    display: block;
    margin: auto;
    margin-top: 15%;

    /*Make it a small rectangle so the border will create an L-shape*/
    width: 5px;
    height: 10px;

    /*Add a white border on the bottom and left, creating that 'L' */
    border: solid $middle_font_color;
    border-width: 0 2px 2px 0;

    /*Rotate the L 45 degrees to turn it into a checkmark*/
    transform: rotate(45deg);

  }
  .checkbox_div {
    padding-top: 20px;
  }
}
.fontIcon {
  width: 10px;
  text-align: left;
  padding: 0 10px 0 0 !important;
}

@media screen and (max-width: 1250px) {
  .events_list {
    font-size: 1.6em;
    margin-bottom: 20px;
    min-width: 768px;
    grid-template-columns: repeat(3, 1fr);
    //width: 100%;
    max-width: 1180px;
    padding: 0 50px;
    .event_item .event_img_wrapper figure{
      height: auto;
    }
    .event_item {
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      .event_img_wrapper {
        img {
          height: 100%;
          object-fit: cover;
        }

        h3 {
          font-size: 16px;
          font-family: $header_font;
        }

        .event_date,
        .eventMapLink {
          font-size: 16px;
        }

        .viewButton {
          width: 100%;
          box-sizing: border-box;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .filter {
    form {
      flex-direction: column;

      .filter_item {
        text-align: left;
        margin-top: 20px;
        width: 100%;

        select {
          min-width: 100%;
          height: 50px;
          margin-left: 0;
        }

        label {
          padding-left: 0;
        }
      }
    }
  }
  .events_list {
    min-width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 1em;

    .event_item {
      width: calc(100% - 20px);
      .event_img_wrapper {
        h3 {
          font-size: 16px;
          font-family: $header_font;
        }

        .event_date,
        .eventMapLink {
          font-size: 14px;
        }

        .viewButton {
          width: 100%;
          box-sizing: border-box;
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .section_header_events{
    margin-left: 20px;
    margin-right: 20px;
    font-size: 26px;
    margin-bottom: 25px;
  }
  .career_filter_form{
    padding: 0 20px!important;
    .filter_item{
      margin: 3px 0!important;
    }
  }
  .events_list {
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 1em;
    padding: 0 20px;
    .event_item {
      width: 100%;
      padding: 0;
      margin: 0;
      max-width: none;
    }
  }
}