.faq{
  .accordion .content.is-expanded {
    max-height: 1000px!important;
    overflow: hidden;
  }
    background: $light_main_color;
  h4.section_header_faq{
    text-transform: none;
    color: $middle_font_color;
    font-size: 36px;
    margin-top: 30px;
    font-family: $header_font;
    font-weight: normal;
  }
  h5.faq_header{
    font-family: $average_font;
    font-weight: normal;
    margin-top: 20px;
    color: $middle_font_color;
  }
  p,pre{
    background: none;
    padding: 15px 0;
    text-align: left;
  }
  .how-to-item{
    width: 100%;
    margin-top: 3%;
  }
  .faq_formulas{
    height: 70px;
    &.pic5{
      background: url("../img/rules/5.png");
      background-position: left center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    &.pic4{
      background: url("../img/rules/4.png");
      background-position: left center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    &.pic3{
      background: url("../img/rules/3.png");
      background-position: left center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    &.pic9{
      background: url("../img/rules/9.png");
      background-position: left center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

