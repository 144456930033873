.main_404{
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5%;
  img{
    width: 100%
  }
  .error_descr_wpapper{
    text-align: center;
    .error_descr{
      font-family: $average_font;
      font-size: 1.3em;
      color: $middle_font_color;
      text-align: center;
      margin: 5% 0;
    }
    .return_main{
      text-decoration: none;
      color: $middle_font_color;
      text-transform: uppercase;
      padding: 10px;
      font-family: $header_font;
      font-size: 1.5em;
      border: 2px dotted $middle_font_color;
      outline: none;
      border-radius: 5px;
      background-color: inherit;
      transition: background-color 300ms linear;
      &:hover{
        border: 3px solid $contrast_color;
        background-color: $contrast_color;
        color: $light_main_color;
      }
    }
  }

}

@media only screen and (max-width: 768px) {
  .main_404{
    margin-top: 60px;
    padding: 20px!important;
    .error_descr_wpapper{
      .error_descr{
        font-size: 1em;
        margin-bottom: 20px;
      }
      .return_main{
        width: 100%;
        max-width: 400px;
        font-size: 1em;
      }
    }
  }
}