.infographic{
  margin: 2% auto;
  width: 100%;
  height: auto;
  //border: 1px solid $light_border;
}
.about_container{
  padding: 50px;
  display: flex;
  justify-content: center;
  background: $middle_main_color;
  margin: auto;
  &.contactForm{
    padding: 0;
  }
  &.uniqueness{
    background: $accent_dark;
    .about_wrapper {
      h4, h1{
        color: $light_main_color;
        text-align: center;
        margin-bottom: 40px;
      }
      p, pre {
        color: $light_main_color;
        text-align: center;
        padding: 30px 0;
        &.border{
          background: url(../img/border-white.png) no-repeat center bottom;
        }
      }
      a{
        color: #ffffff;
      }
    }
  }

  .about_wrapper{
    max-width: 1180px;
    margin: 0 auto;
    h4, h1{
      font-family: $header_font;
      font-weight: normal;
      font-size: 2em;
      color: $dark_font_color;
      text-transform: none;
      padding: 0 7%;
      margin-top: 0;
      text-align: center;
    }
    .subHeader{
      color: $light_main_color;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 0.06em;
      font-size: 26px;
      padding:0!important;
    }
    .verticalLine{
      color: $light_main_color;
      display: flex;
      justify-content: center;
      padding: 5px 0;
    }
    .blogLink{
      color: $light_main_color;
      text-align: center;
      font-size: 13px;
    }
    h5{

      font-family: $average_font;
      font-weight: 700;
      font-size: 1.3em;
      color: $dark_font_color;
      padding: 0 7%;
      margin-top: 3%;
      text-align: center;
    }
    p,pre{

      line-height: 30px;
      max-width: 800px;
      font-family: $average_font;
      font-size: 16px;
      color: $middle_font_color;

      border: 0px!important;
      &.border{
        background: url(../img/border.png) no-repeat center bottom;
        padding: 30px 0;
        text-align: center;
      }
      span{
        font-family: $bold_font;
        color: $middle_font_color;
      }
    }
    p{
      padding: 0;
    }
  }
  .about_text{
    flex-grow: 1;
    flex-basis: 0;
    padding-bottom: 2%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .competition_buttons{
      width: 90%;
      background-color: #fff;
      display: grid;
      grid-template-columns: 50% 50%;
      grid-auto-rows: max-content;
      grid-template-rows: 50% 50%;
      grid-row: 50% 50%;
      grid-column-gap: 10px;
      grid-row-gap: 20px;
      position: relative;
      margin-bottom: 20px;
      margin-right: 10px;
    }

    .registration_buttons{

      display: flex;
      justify-content: center;
      a{
        background-color: $contrast_color;
        width: 90%;
        max-width: 300px;
        text-align: center;
        color: $light_main_color;
        text-transform: uppercase;
        padding: 10px;
        font-family: $average_font;
        font-weight: bold;
        font-size: 0.8em;
        border: none;
        outline: none;
        border-radius: 5px;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;

      }
    }
  }
  @media only screen and (max-width: 1200px) {
    .about_text{
      .competition_buttons{
        width: 90%;
        background-color: #fff;
        display: grid;
        grid-template-columns: 100%;
        grid-auto-rows: max-content;
        grid-template-rows: none;
        grid-column-gap: 10px;
        grid-row-gap: 20px;
        position: relative;
        margin-bottom: 20px;
        margin-right: 10px;
        a{
          margin: 0 auto;
        }
      }
    }

  }
  .about_image{
    flex-grow: 1;
    flex-basis: 0;
    max-width: 700px;
    background-image: url("../img/charts_color.jpg");
    background-size: cover;
  }
  .competition_image{
    max-width: 700px;
    flex-grow: 1;
    flex-basis: 0;
    background-image: url("../img/competition_color.jpg");
    background-size: cover;
  }

}

.google_map_container {
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 10%;
  padding-right: 10%;
}

@media screen and (max-width: 1280px) {
  .google_map_container {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.topRankUsers {
  background: #f0f5f7;

  .globalRankWrapper{
    min-height: 254px;
  }

  .topRankUsersTable {
    background: #ffffff;
    width: 100%;
    min-width: 500px;
    border-collapse: collapse;
    overflow: hidden;
    border: 1px solid #d9e1e6;
    margin-top: 15px;

    tr {
      text-align: center;
      vertical-align: bottom;
      border: none;
    }

    tr th {
      background: #223c55;
      padding: 10px 5px;
      color: #ffffff !important;
      font-family: "Gotham Pro Regular";
      border: 1px solid transparent;
      text-align: center;
      font-weight: normal;
      font-size: 1em;
    }

    tr:nth-child(even) {
      background-color: #f2f5f6;
    }
  }
  .topRankUsersButtonWrapper{
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;

    .rankUsersLeaderboardLink {
      background: none;
      border: 1px solid $dark_font_color;
      color: $dark_font_color;
      padding: 20px;
      margin-top: 25px;
      text-decoration: none;
    }
    .rankUsersLeaderboardLink :hover{

      text-decoration: underline;
    }
  }
}


.top_list tr:nth-child(2n+0) {
  background-color: #d9e1e6;
}

.topRankUsersAboutPrize{
  display: flex;
  text-align: center;
  line-height: 50px;
  font-size: 1.2em;
  padding: 3% 15px;

  div{
    flex: 50%;
  }
  span{
    width: 1px;
    background: #001115;
    margin-left: 1em;
    margin-right: 1em;
  }
  strong{
    font-weight: bold;
    min-width: 9ex;
    display:inline-block;
  }

  .topRankUsersAboutPrizePotential{
    text-align: right;
  }

  .topRankUsersAboutPrizePaid{
    text-align: left;
  }
  .topRankUsersAboutSeparator{
    width: 1px;
    background: #0e0e0e;
  }
}

.about_container.competition_container{
  background-color: $light_main_color;
}
.body_intro_wrapper{
  background: $middle_main_color;
  overflow: hidden;
}
.body_intro{
  background: url("../img/netBackground.jpg") center center;
  background-color: #f0f5f7;
  display: flex;
  align-items: center;

  .body_intro_slogan {
    display: flex;
    margin: auto;
    flex-direction: column;
    margin-top: 90px;

  }
  .body_intro_container{
    display: flex;
    flex-direction: column;
    align-items: center;

    p{
      color: #ffffff;
      margin-bottom: 30px;
      text-align: center;
      line-height: 30px;
      //width: 80%;
      font-size: 1.2em;
    }
    strong {
      color: #ff6b0d;
      font-weight: bold;
    }
    h2{
      color: #ffffff;
      margin-bottom: 30px;
      text-align: center;
      line-height: 30px;
      //width: 80%;
      font-size: 2em;
      font-weight: bold;
    }
  }
  .quantiacs_body_intro_container{
    display: flex;
    flex-direction: column;
    align-items: center;

    p{
      color: #ffffff;
      margin-bottom: 30px;
      text-align: center;
      line-height: 30px;
      //width: 80%;
      font-size: 1.2em;
    }
    strong {
      color: #1bbafb;
      font-weight: bold;
    }
    h2{
      color: #ffffff;
      margin-bottom: 30px;
      text-align: center;
      line-height: 30px;
      //width: 80%;
      font-size: 2em;
      font-weight: bold;
    }
  }
  .body_intro_text{
    font-family: $average_font;
    font-weight: normal;
    text-align: center;
    color: $light_main_color;
    padding: 0 25px;
    font-size: 2em;
    margin-bottom: 30px;
  }
  .startLink{

    text-align: center;
    color: $light_main_color;
    background: $contrast_color;
    border: none;
    cursor: pointer;
    padding: 15px 15px;
    padding-top: 15px;
    text-decoration: none;
    font-family: $average_font;
    font-size: 1em;
    font-weight: bold;
    transition: all 300ms ease-in;
    min-width: 200px;
    width: 200px;
    height: 50px;
  }
  .quantiacs_start_link{

    text-align: center;
    color: $light_main_color;
    background: #1bbafb;
    border: none;
    cursor: pointer;
    padding: 15px 15px;
    padding-top: 15px;
    text-decoration: none;
    font-family: $average_font;
    font-size: 1em;
    font-weight: bold;
    transition: all 300ms ease-in;
    min-width: 200px;
    width: 200px;
    height: 50px;
  }
  .body_intro_description{
    font-family: $average_font;
    width: 100%;
    font-weight: normal;
    color: $middle_font_color;
    font-size: 1.5em;
    margin: 0;
    padding: 5% 3%;
    text-align: justify;
  }
}
.section_header_faq{
  padding-top: 3%;
  margin-bottom: 0;
  text-align: center;
  text-transform: uppercase;
  font-family: $average_font;
  font-weight: bold;
  color: $dark_font_color;
  font-size: 1.6em;
}
.thurd_line{

  .body_offer{
    padding: 50px;
    .section_header{
      text-align: center;
      text-transform: none;
      font-family: $header_font;
      font-weight: normal;
      color: $middle_font_color;
      margin-top: 0;
      font-size: 36px;
      margin-bottom: 40px;

    }
  }

  .offer_container{
    margin: auto;
    max-width: 1180px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding-bottom: 0;
    .offer_item {
      position: relative;
      word-wrap: break-word;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: space-between;
      border-radius: 5px;
      margin-top: 0;
      width: 275px;
      img{
        width: 85px;
        min-height: 106px;
        vertical-align: middle;
        border-style: none;
      }
      &.learn{
        img{
          width: 69px;
          min-height: 106px;
        }
      }
      .offer_p_wrapper{
        width: 100%;
        position: relative;
      }
      h3 {
        font-family: $header_font;
        font-weight: normal;
        color: $middle_font_color;
        text-transform: none;
        font-size: 21px;
        margin: 25px 0 20px 0;
        text-align: center;
      }
      .offer_p {
        font-family: $average_font;
        color: $middle_font_color;
        text-align: center;
        white-space: pre-wrap;       /* css-3 */
        white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
        white-space: -o-pre-wrap;    /* Opera 7 */
        word-wrap: break-word;
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 1rem;
      }
      a{

        text-align: center;
        color: $dark_main_color;
        background: $light_main_color;
        text-transform: uppercase;
        padding: 10px 25px;
        font-family: $average_font;
        font-weight: 400;
        font-size: 0.9em;
        outline: none;
        border-radius: 5px;
        text-decoration: none;
        border: 1px solid $dark_main_color;
        bottom: 5%;
        margin: 10px;
        width: fit-content;
        transition: all 300ms ease-in;
        &.link_button_strategy{
          color: $light_main_color;
          background: #219ada;
          border-color: #219ada;
        }

        &:hover{
          color: $light_main_color;
          background: $contrast_color;
          border: 1px solid $contrast_color;
        }
      }
      a.link_button_tutorial{

        text-align: right;
        cursor: pointer;
        background: none;
        position: relative;
        min-width: 200px;
        color: #666666;
        text-transform: uppercase;
        font-family: "Roboto", sans-serif;
        font-weight: bold;
        font-size: 0.8em;
        border: none;
        outline: none;
        border-radius: 5px;
        text-decoration: underline;
        margin: 0;
        padding-left: 0;
        margin-top: 10%;
        &:hover{
          color: $contrast_color;
        }
      }
    }
  }
}
.video_container{
  background: url("../img/netBackground.jpg") no-repeat center center;
  padding: 50px;
  height: fit-content;
  .video_wrapper{
    max-width: 1180px;
    margin: 0 auto;
    .video{
      position: relative;
      padding-bottom: 380px;
      padding-top: 25px;
      height: 0;
      max-width:640px;
      margin:0 auto;
    }
  }
}

.competitions_results {
  h2 {
    padding-top: 50px;
    padding-bottom: 50px;
    font-family: "Gotham Pro Medium";
    font-weight: normal;
    text-align: center;
    color: #666666;
    font-size: 36px;
  }

  .competitions_results_button_wrapper {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 25px 0;

    .leaderbord_link {
      background: none;
      border: 1px solid #333;
      color: #333;
      padding: 20px;
      margin-top: 25px;
      text-decoration: none;
    }
  }
}

.competitions_results_competition_all {
  font-family: "Gotham Pro Regular";
  color: #333333;
  width: 100%;
  max-width: 1210px;
  margin: auto;
  overflow-x: auto;
  overflow-y: auto;
  padding-left: 15px;
  padding-right: 15px;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    border: none;
    background: #ffffff;


    thead {
      tr th {
        background: #223c55;
        padding: 10px 5px;
        color: #ffffff !important;
        border: 1px solid #223c55;
      }

      .triangle-up {
        width: 0;
        height: 0;
        border-left: 70px solid transparent;
        border-right: 70px solid transparent;
        border-bottom: 100px solid #6E18C0;
      }

      .triangle-down {
        width: 0;
        height: 0;
        border-left: 70px solid transparent;
        border-right: 70px solid transparent;
        border-top: 100px solid #6E18C0;
      }
    }

    tbody {
      td {
        padding: 0.75rem;
        vertical-align: top;
        word-break: break-all;

        span {
          font-weight: bold;
        }
      }

      tr {
        text-align: center;
        vertical-align: bottom;
      }

      tr.tr_competition:nth-child(even) {
        background-color: #f2f5f6;
      }
    }

    .tr_group_month_year {
      background-color: #d9e1e6;

    }
  }
}

@media screen and (max-width:768px){
  .about_wrapper{
    padding: 50px;
    &.about_wrapper_faq{
      padding: 50px 0;
      h4.section_header_faq{
        font-size: 26px;
      }
    }
    p,pre{
      text-align: center;
      font-family: $average_font;
      background: none!important;
      padding: 8px 0!important;
    }
  }
  .body_intro {
    border-radius: 0;
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    height: auto !important;
    align-items: flex-end;
    .startLink{
      margin-top: 30px;
    }
    .body_intro_slogan {
      justify-content: center;
    }

    .body_intro_container {
      margin: 0 auto;

      .body_intro_text {
        padding: 0;
        font-size: 2em;
      }
    }
  }
  .about_container {
    background: $middle_main_color;
    &.contactForm {
      flex-direction: column;

      .teamPhoto {
        max-width: 100%;
        .socButtonWrapper {
          padding: 2%;
          margin-bottom: 30px;

          a {
            margin: 0 30px 0 0;
            font-size: 30px;
          }
        }
      }

      .about_wrapper {
        .contactFormContainer {
          .contactForm {
            form {
              width: 100%;
              input, textarea {
                font-size: 1.2em;
                padding: 15px;
                box-sizing: border-box;
              }

              .personaldataWrapper {
                font-size: 18px;
              }

              input[type=submit] {
                max-width: 100% !important;
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
    .about_wrapper {
      h1, h4 {
        font-size: 26px;
      }
    }
  }
  .thurd_line{
    .offer_container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex-flow: column wrap;
        align-content: center;

      .offer_item {
        &.arrow{
          display: none;
        }
        width: 100%;
      }
    }
  }
  .video_container{
    padding: 50px;
    height: fit-content;
    .video_wrapper{
      .video{
        position: relative;
        padding-bottom: 250px;
        padding-top: 25px;
        height: 0;
        max-width:500px;
        margin:0 auto;
      }
    }
  }

  .topRankUsersAboutPrize {
    display: block;

    .topRankUsersAboutSeparator {
      display: none;
    }

    .topRankUsersAboutPrizePotential {
      text-align: center;
    }

    .topRankUsersAboutPrizePaid {
      text-align: center;
    }
  }

  .competitions_results_competition_all {
    .th_start, .th_finish, {
      display: none;
    }

    .td_start, .td_finish, {
      display: none;
    }
  }

}

.home_video_quick_start {
  display: none;
}

@media screen and (min-width: 768px) {
  .home_video_quick_start {
    display: block;
    padding-top: 25px;
    max-width: 1180px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 480px) {
  .body_offer {
    padding: 20px !important;
  }
  .about_container{
    .about_wrapper {
      h1, h4 {
        font-size: 26px;
        margin-bottom: 25px!important;
      }
      pre{
        font-size: 16px;
        line-height: 30px;
      }
    }
  }
  .video_container{
    padding: 20px;
    .video{
      position: relative;
      padding-bottom: 200px;
      padding-top: 25px;
      height: 0;
      max-width:300px;
      margin:0 auto;
    }
  }
  .competitions_results {
    h2 {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
  .competitions_results_competition_all {

    .th_start, .th_finish, .th_type{
      display: none;
    }

    .td_start, .td_finish, .td_type {
      display: none;
    }

  }
  h1{
    font-size: 28px!important;
  }
  h2 {
    font-size: 26px !important;
  }
  h3{
    font-size: 21px !important;
  }
}