/*плавная загрузка страниц*/
@-webkit-keyframes fade-in {   0% { opacity: 0.1; }   100% { opacity: 1; }   }
@-moz-keyframes    fade-in {   0% { opacity: 0.1; }   100% { opacity: 1; }   }
@-o-keyframes      fade-in {   0% { opacity: 0.1; }   100% { opacity: 1; }   }
@keyframes         fade-in {   0% { opacity: 0.1; }   100% { opacity: 1; }   }

@import url('https://fonts.googleapis.com/css?family=Roboto:400,700');
@import url('https://fonts.googleapis.com/css?family=Oswald');
@import url('https://fonts.googleapis.com/css?family=Merriweather:300|Montserrat+Alternates:400,600,700,800&display=swap&subset=cyrillic');
@import url('https://fonts.googleapis.com/css?family=Montserrat:700|PT+Sans:700|Rubik:700,900|Source+Sans+Pro:400,700|Ubuntu:700&display=swap');

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Light'), local('Gilroy-Light'), url('/fonts/Gilroylight.woff2') format('woff2'), url('/fonts/Gilroylight.woff') format('woff'), url('/fonts/Gilroylight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy ExtraBold'), local('Gilroy-ExtraBold'), url('/fonts/Gilroyextrabold.woff2') format('woff2'), url('/fonts/Gilroyextrabold.woff') format('woff'), url('/fonts/Gilroyextrabold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro Light';
  src: url(GothamPro-Light.eot);
  src: url(GothamPro-Light.ttf);
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham Pro Regular';
  src: url(GothamPro.eot);
  src: url(GothamPro.ttf);
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham Pro Medium';
  src: url(GothamPro-Medium.eot);
  src: url(GothamPro-Medium.ttf);
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham Pro Bold';
  src: url(GothamPro-Bold.eot);
  src: url(GothamPro-Bold.ttf);
  font-weight: normal;
  font-style: normal;
}

:root {
  --footer-color: white;
  //--footer-color: #f16c20;
}

// Main colors
$light_main_color: #ffffff;
$dark_main_color:#020202;
//$dark_main_color:#0d3c55;
$middle_main_color:#f0f5f7;
$light_border: #cccccc;
$contrast_color: #d66d36;
//$contrast_color:#f16c20;
$additional_color:#1395ba;

$dark_font_color: #333333;
$middle_font_color:#666666;
$light_font_color:#999999;
$accent_color:#ff6666;
$accent_dark:#223c55;

//Fonts

$average_font: "Gotham Pro Regular";
$bold_font:"Gotham Pro Bold";
$header_font:'Gotham Pro Medium';
$light_font:'Gotham Pro Light';




@mixin animation($time) {
  -webkit-animation: $time; /* Safari 4+ */
  -moz-animation: $time; /* Fx 5+ */
  -o-animation: $time; /* Opera 12+ */
  animation: $time; /* IE 10+, Fx 29+ */
}
@mixin transition($t_time){
  -webkit-transition: $t_time;
  -moz-transition: $t_time;
  -o-transition: $t_time;
  transition: $t_time;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  //font-size: 16px;
  font-family: $average_font;
  color: #666;
  font-weight: normal;
}
body{
  overflow-y: scroll;
}
body, html {
  height: 100%;
}
pre{
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
#root, .wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between; }

main {
  flex: 1 0 auto; }

body {
  overflow-x: hidden;
  margin: 0;
  background-color: $light_main_color;
}

h1 {
  text-align: center;
  margin-top: 3rem;
  text-transform: uppercase;
  font-size: 3.5rem;
}
select {
  width: 150px;
  padding: 5px 35px 5px 5px;
  font-size: 16px;
  border: 1px solid $light_main_color;
  height: 34px;
  background-color: inherit;
  margin-left: 5px;
  color: $light_main_color;;
  outline: none;
}

.errorWrapper{
  width: 100%;
  max-width: 500px;
  margin: 3% auto;
  padding: 3%;
  padding-bottom: 5%;
  text-align: center;
 h4{
   font-family: $average_font;
   font-size: 1.5em;
   font-weight: normal;
   color: $middle_font_color;

 }
  a{
    background: $contrast_color;
    color: $light_main_color;
    text-decoration: none;
    padding: 10px 20px;
    text-transform: uppercase;
    font-family: $average_font;
    border-radius: 5px;
  }
}
.career_main_body{
  max-width: 1180px;
  margin: 0 auto;
}

@import "header";
@import "homepage";
@import "global_list_mini";
@import "career";
@import "students";
@import "interviews";
@import "accordion";
@import "contacts";
@import "termsofuse";
@import "events";
@import "openpositions";
@import "rules";
@import "vacancyDescription";
@import "404";
@import "faq";
@import "learn";
@import "cookies";
@import "firstBitcoinContest";
@import "competitions";
