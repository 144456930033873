.body_intro.body_intro_interviews {
  background-image: url(../img/Interview.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  .body_intro_container {
    width: min-content;
    min-width: 500px;
    margin-left: 0;
  }
  .body_intro_text {
    width: 90%;
    margin: auto;
    color: $contrast_color;
  }
}
.about_wrapper.about_wrapper-career {
  width: 100%;
  max-width: 1500px;
  h4 {
    text-align: center;
  }
  p,
  pre {
    font-size: 1em;
  }
  pre {
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
  }
}
.how-to-item {
  margin: 5% auto;
  width: 90%;
  .how-to-item__image {
    font-family: $average_font;
    background: $contrast_color;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: $light_main_color;
    margin: 0 auto 10px;
    line-height: 50px;
    text-align: center;
    font-size: 30px;
  }
  h2,
  h3 {
    font-family: $average_font;
    font-weight: bold;
    color: $middle_font_color;
    text-transform: uppercase;
    text-align: center;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.2em;
  }
  .mar_p {
    padding-right: 5%;
  }
}
.about_wrapper {
  .additionalInfoWrapper {
    padding: 0 7%;
    padding-right: 2%;
    display: flex;
    justify-content: space-between;
    div {
      width: 100%;
      h3 {
        margin: 0;
      }
      h3,
      p {
        padding: 0;
      }
      pre {
        padding: 0 10px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .about_container {
    padding: 5%;
    .how-to-item {
      width: 100%;
    }
  }
}