.vacancyDescription {
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 1180px;
  width: 100%;
  margin: auto;
  line-height: 1.3em;

  .vacancyWrapper {
    max-width: 100%;
    background-color: $light_main_color;
  }

  .vacancyItemWrapper {

    h2 {
      font-family: $header_font;
      font-size: 1.5em;
      color: $middle_font_color;
      margin-top: 0;
      margin-bottom: 10px;
    }

    h4 {
      text-align: left;
      font-size: 16px;
      font-family: $header_font;
      margin: 20px 0;
    }

    ul {
      padding-left: 20px;
    }

    .cityText {
      font-family: $average_font;
      color: $middle_font_color;
      font-size: 1em;
      margin-top: 0;
      padding-bottom: 10px;
      margin-bottom: 20px;
    }

    .section_text {
      font-family: $average_font;
      text-align: left;
      font-size: 1em;
      color: $middle_font_color;
    }

    .section_text.section_text_availability {
      margin-top: 7%;
      text-decoration: underline;
    }

    .container {
      color: $middle_font_color;
      text-align: left;
      font-family: $average_font;
    }
  }
}

.returnButton {
  padding-top: 20px;
  color: $middle_font_color;

  &:hover {
    color: $light_font_color;
    text-decoration: underline;
  }
}

.actionsWrapper {
  padding: 20px 0;

  p {
    a {
      color: $middle_font_color;
      text-decoration: none;
      font-family: $average_font;
      font-size: 1em;

      span {
        font-weight: bold;
        color: $contrast_color;
        font-size: 1em;
      }
    }
  }
}

@media only screen and (max-width: 1500px) {
  .vacancyDescription {
    padding: 0;
  }
}