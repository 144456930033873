html,
body,
#app {
  height: 100%;
}

.container {
  min-height: 100%;
  font-family: $average_font;
  text-align: center;
  .accordionItem {
    margin-top: 10px;
  }
  h1 {
    text-align: center;
    color: #1569a8;
  }
}

.accordion {
  margin: 0 auto;
   .title {
    padding: 10px 30px;
    cursor: pointer;
    transform: translate3d(0, 0, 0);
    color: $middle_font_color;
    position: relative;
    font-size: 1em;
    background:$middle_main_color;
    text-align: left;

    &::after {
      content: "+";
      font-size: 18px;
      color: $middle_font_color;
      font-weight: bold;
      transition: transform 0.5s ease-in-out;
      position: absolute;
      right: 20px;
      top: 8px;
      font-family: monospace;
    }

    &.is-expanded {
      transition: background 0.5s;
      //background: #0e4671;

      &::after {
        content: "-";
        transform: rotate(-360deg);
      }
    }
  }

  .content {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s;
    margin: 0;
    padding: 0 30px;
    border: solid 1px $middle_main_color;
    border-top: 0;
    background: $light_main_color;
    text-align: left;

    p {
      padding: 30px 0;
      margin: 0;
      opacity: 0;
      transition: 0.5s;
    }

    &.is-expanded {
      max-height: 500px;
      overflow: hidden;
      p {
        opacity: 1;
        background: none;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .accordion {
    width: 100%;
    .accordionItem {
      font-size: 14px;
    }
  }
}