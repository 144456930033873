.body_intro_wrapper{
  .body_intro_bitcoin{
    margin-top: 50px;
    background: linear-gradient(to top, #020202 2%, transparent 10%, transparent 95%, #020202 100%), url("../img/Bitcoin_background.jpg") no-repeat center center;
    background-position: center;
    background-size:cover;

    background-color: $dark_main_color;
    display: flex;
    align-items: center;
    width: 120%;
    margin-left: -10%;
    margin-right: 10%;
    height: 700px;
    .body_intro_slogan {
      display: flex;
      margin: auto;
      flex-direction: column;
      margin-top: 150px;

    }
    .body_intro_container{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .sub_title{
      font-family: $average_font;
      font-weight: normal;
      text-align: center;
      color: $light_font_color;
      padding: 0 25px;
      font-size: 1.1em;
      margin-bottom: 25px;
    }
    .date_title{
      span{
        font-family: $average_font;
        font-weight: normal;
        color: $contrast_color;
        font-size: 1em;
      }
      .timer{
        min-width: 200px;
        text-align: left;
      }
      font-family: $average_font;
      font-weight: normal;
      color: $contrast_color;
      padding: 0;
      font-size: 1.1em;
      margin-top: 25px;
      width: 100%;
      text-align: left;
    }
    .title{
      text-align: center;
      span{
        font-family: $light_font;
        font-weight: normal;
        color: $light_main_color;
        font-size: 2.5em;
        text-transform: uppercase;
        &.strong{
          font-family: $header_font;
        }
      }
    }

  }

}
.about_container.about_container_firstBitcoinContest{
    padding: 150px 50px ;
    background: $dark_main_color;
    .about_wrapper{
      pre{
        font-family: $light_font;
        text-align: center;
        color: $light_main_color;
        font-size: 1.5em;
        line-height: 2em;
      }
    }
}
.termsWrapper {
  &.termsWrapper_bitcoin {
    .termsWrapper_bitcoin_background{
      background: linear-gradient(to top, #020202 2%, transparent 10%, transparent 95%, #020202 100%), url("../img/Bitcoin_background.jpg") no-repeat center center;
      background-position: top;
      background-size:cover;
      .middle_theme{
        background: transparent;
      }
      padding-bottom: 10%;
    }
    max-width: 100%;
    padding: 0;
    margin: 0;
    color: $dark_font_color;
    background: $dark_main_color;
    .generalConditions{
      max-width: 700px;
      margin: 0 auto;
      .section_text{
        color: #cfcfcf!important;
      }
    }
    h1 {
      text-align: center;
      font-size: 36px;
      margin-bottom: 40px;
      font-family: $average_font;
    }
    .section_header {
      text-align: center;
      font-size: 36px;
      margin-bottom: 40px;
      font-family: $average_font;
    }
    .section_text {
      font-family: $average_font;
      white-space: pre-wrap;      /* css-3 */
      white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
      white-space: -pre-wrap;     /* Opera 4-6 */
      white-space: -o-pre-wrap;   /* Opera 7 */
      word-wrap: break-word;      /* Internet Explorer 5.5+ */
      line-height: 30px;
      text-align: center;
      max-width: 1180px;
      margin: 0 auto;
      .infographic{
        width: 80%;
      }

      &.bold{
        font-family: $header_font;
      }
    }
    section {
      padding: 50px;
      &.prize_theme{
        min-height: 400px;
        background: $dark_font_color;
        padding: 50px;
        padding-top: 70px;
        .section_header{
          color:$light_main_color;
          text-align: center;
          margin-bottom: 30px;
        }
        .section_text{
          color: $light_font_color;
          text-align: center;
          margin-bottom: 70px;
          font-size: 16px;
          line-height: normal!important;
        }
        .prize_wrapper{
          max-width: 1180px;
          margin: 0 auto;
          display: flex;
          justify-content: space-around;
          align-items: baseline;
          .prize_item{
            .prize_header{
              .prize_value{
                font-size: 2.5em;
                color: $light_main_color;
                font-family: $header_font;
                &.prize_value_big{
                  font-size: 3.5em;
                }
              }
            }
            .prize_place{
              font-family: $header_font;
            }
          }
        }
      }
      &.dark_theme{
        min-height: 400px;
        background: $dark_font_color;
        padding: 0 50px;
        .left{
          max-width: 500px;
          min-height: 400px;
          padding-left: 50px;
        }
        .right{
          background: url("../img/Bitsymb.png") no-repeat center right;
          background-size: contain;
          min-height: 400px;
        }
        pre{
          text-align: left;
        }
        .section_header{
          color:$light_main_color;
          text-align: left;
          margin-top: 80px;
          margin-bottom: 30px;
        }
        .section_text{
          color: $middle_main_color;
          text-align: left;
          margin-bottom: 80px;
          font-size: 16px;
          line-height: normal!important;
          a{
            color: $middle_main_color;
          }
        }
      }
      &.middle_theme{
        padding-top: 70px;
        pre{
          text-align: left;
        }
        background: $dark_main_color;
        .section_header,
        .section_text{
          color:$light_main_color;
        }
        .section_subheader{
          color: $light_font_color;
          font-size: 18px;
        }
        .section_text{
          margin-bottom: 10px;
          font-size: 13px;
          line-height: normal!important;
          color:$middle_main_color;
          a{
            font-size: 13px;
            color:$middle_main_color;
          }
        }
      }
      .flex-wrapper{
        max-width: 1100px;
        margin: 0 auto;
        .left{
          padding: 0 20px;
        }
        .right{
          padding: 0 20px;
          padding-left: 50px;
        }
        .left,
        .right{
          flex: 1 1 50%;


          .rules-item{
            margin-bottom: 40px;
            max-width: 400px;
            h4{
              text-align: left;
              font-family: $average_font;
              margin-bottom: 10px;
              font-size: 18px;
              text-transform: uppercase;
              span{
                display: inline-flex;
                margin-right: 10px;
                background: $contrast_color;
                border-radius: 50%;
                width: 24px;
                height: 24px;
                text-align: center;
                color: #fff;
                align-items: center;
                justify-content: center;
              }
            }
            .section_text{
              text-align: left;
              line-height: 25px;

            }
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 768px) {
  .body_intro_wrapper{
    .body_intro_bitcoin{
      height: 500px;
      .sub_title,
      .date_title{
        font-size: 1em;
        padding: 0 20px;
        max-width: 270px;
        text-align: center;
      }
      .title{
        padding: 0 20px;
        span{
          font-size: 1.3em;
        }
      }
    }
  }
  .about_container.about_container_firstBitcoinContest{
    padding: 50px 20px ;
    background: $dark_main_color;
    .about_wrapper{
      pre{
        text-align: center;
        color: $light_main_color;
        font-size: 1em;
        line-height: 1.5em;
      }
    }
  }
  .termsWrapper {
    &.termsWrapper_bitcoin {
      section {
        &.prize_theme{
          min-height: 400px;
          background: $dark_font_color;
          padding: 50px;
          .section_header{
            color:$light_main_color;
            text-align: center;
            margin-bottom: 30px;
          }
          .section_text{
            color: $light_font_color;
            text-align: center;
            margin-bottom: 40px;
            font-size: 16px;
            line-height: normal!important;
          }
          .prize_wrapper{
            max-width: 1180px;
            margin: 0 auto;
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            align-items: center;
            .prize_item{

              padding-bottom: 30px;
              display: flex;
              flex-direction: column-reverse;

              .prize_header{
                padding-left: 20px;
                .prize_value{
                  font-size: 2em;
                  color: $light_main_color;
                  font-family: $header_font;
                  &.prize_value_big{
                    font-size: 2.5em;
                  }
                }
              }
              .prize_place{
                width: 100%;
                text-align: center;
              }
            }
          }
        }

        &.dark_theme{
          min-height: 200px;
          padding: 20px 0;
          background: $dark_font_color;
          background-image: url("../img/Bitsymb.png");
          background-repeat: no-repeat;
          background-position: center right;
          background-size: contain;
          .left{
            max-width: 100%;
            min-height: 200px;
            padding-left: 0;
            .section_text{
              max-width: 400px;
            }

          }
          .right{
            display: none;
          }
          pre{
            text-align: left;
          }
          .section_header{
            color:$light_main_color;
            text-align: center;
            margin-top: 40px;
            margin-bottom: 30px;
          }
          .section_text{
            color: $light_font_color;
            text-align: center;
            margin-bottom: 80px;
            font-size: 16px;
            line-height: normal!important;
          }
        }
        &.middle_theme{
          .section_text{
            font-size: 12px;
          }
          .section_subheader{
            font-size: 14px!important;
          }
        }
      }
    }
  }
}