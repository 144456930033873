.wrapper_openpositions{
  padding: 50px;
  margin-top: 58px;
  .container{
    max-width: 1180px;
    margin: 0 auto;
  }

  .vacancies_list{
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    text-align: left;
  }
  .vacancies{
    position: relative;
    height: 100%;
  }
  .vacancies_item{
    background-color: $middle_main_color;
    padding: 1% 5% 1% 2%;
    margin: auto;
    margin-bottom: 1.5em;
    font-size: 0.9em;
    width: 100%;
    span{
      font-family: $average_font;
      font-size: 1em;
      color: $middle_font_color;
    }
    a{
      text-decoration: none;
    }
    h3{
      text-decoration: none;
      font-family: $bold_font;
      color: $middle_font_color;
      margin-top: 0;
      font-size: 18px;
      margin-bottom: 15px;
    }
    p{
      font-family: $average_font;
      color: $middle_font_color;
      font-size: 1.1em;
      margin-bottom: 10px;
    }
  }
  #empty_response{
    font-family: $bold_font;
    font-weight: bold;
    color: $middle_font_color;
    font-size: 1.3em;
  }
  .vacancies_text{
    font-family: $average_font;
    color: $middle_font_color;
    font-size: 1em;
    margin-bottom: 10%;
    label{
      font-family: $bold_font;
      font-weight: bold;
    }
  }
  .additional_vacans_wrap{
    position: relative;
    height: inherit;
    .additioal_vacans_info{
      height: 500px;
      overflow: hidden;
      position: relative;
      img {
        height: 100%;
        width: auto;
        margin-left: -25%;
      }
    }
  }
  .vacancies_wrapper{
    background-color: $light_main_color;
    padding: 5%;
    margin-right: 5%;
    h3{
      font-family: $average_font;
      font-weight: bold;
      font-size:1.7em;
      color: $middle_font_color;
    }
    a{
      font-family: $average_font;
      font-size: 1.2em;
      font-weight: bold;
      text-transform: uppercase;
      padding: 5px;
      border-radius: 5px;
      margin-left: 3%;
    }
    .return{
      border: 1px solid $light_font_color;
      color: $light_font_color;
      background-color: $light_main_color;
    }
    .reply{
      margin-left: 3%;
      padding: 8px 10px;
    }
  }
  .key_words{
    padding: 0;
    font-family: $average_font;
    color: $dark_font_color;
    li{
      display: inline-block;
      background-color: #dadada;
      padding: 5px;
      margin: 5px;
      margin-left: 0;
      cursor: pointer;
    }
  }
  .filter_input{
    font-size: 16px;
    padding: 0 20px;
    outline: none;
    border: 1px solid $light_font_color;
    color: #666666;
    font-family: $average_font;
    text-transform: none;
    background-color: inherit;
    margin-left: 5px;
    min-width: 300px;
    height: 40px;
    padding-left: 10px;
  }
  select.filter_input{
    height: 40px;
  }

  .filter_positions{
    form{
      max-width: 1200px;
      margin: 0 auto;
      margin-bottom: 2em;
      display: flex;
      justify-content: space-evenly;
      align-items: stretch;

    }

  }
}
@media screen and (max-width: 768px) {
  .filter_positions{
    form{
      max-width: 1200px;
      margin: 0 auto;
      margin-bottom: 2em;
      display: flex;
      flex-direction: column;
      .filter_input{
        width: 100%;
        margin: 0;
        margin-bottom: 10px;
        min-width: 0;
      }
      select.filter_input{
        width: 100%;
      }
    }
  }
}
@media (max-width: 480px){
  .wrapper_openpositions{
    padding: 20px!important;
  }
}