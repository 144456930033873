.body_intro.body_intro_students{
  background-image: url(../img/study_intro.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  .body_intro_container{
    width: min-content;
    min-width: 500px;
    margin-left: 0;
  }
  .body_intro_text{
    width: 90%;
    margin: auto;
    color: $contrast_color;
  }
}
.about_wrapper.about_wrapper-career{
  width: 100%;
  max-width: 1500px;

  h4{
    text-align: center;
  }
  p,pre{
    font-size: 1em;
    text-align: left;
  }
  ul{
    padding: 0 8%;
  }
  pre{
    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
  }
}