.body_intro.body_intro_career {
  background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.5)), url(../img/career_intro.jpg);
  background-position: center;
  .body_intro_container {
    width: min-content;
    min-width: 500px;
    margin-left: 0;
    margin: 0 auto;
  }
  .body_intro_text {
    white-space: nowrap;
    width: fit-content;
    color: $contrast_color;
  }
}
.thurd_line_opportunities {
  background: $middle_main_color;
  padding-bottom: 5%;

  .body_offer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-image: url("../img/seo-stats-graph.png");
    background-repeat: no-repeat;
    background-position: 50% 20%;
    background-size: auto;
    min-height: 600px;
    .section_text {
      font-family: $average_font;
      margin-top: 0;
      text-align: center;
      padding: 0 7%;
      font-size: 1.4em;
      color: $middle_font_color;
      font-weight: normal;
    }
  }
  .career_topmenu {
    display: flex;
    justify-content: center;
    height: 200px;
    .career_nav_item {
      width: 100%;
      max-width: 300px;
      cursor: pointer;
      margin: 0 5%;
      display: flex;
      align-items: flex-end;
      filter: grayscale(100%);
      background-size: cover;
      &:hover {
        filter: grayscale(0);
      }
      a {
        font-family: $average_font;
        text-transform: uppercase;
        text-decoration: none;
        color: $light_main_color;
        font-weight: bold;
        padding: 5px;
        width: 100%;
        height: 100%;
        position: relative;
        span {
          position: absolute;
          bottom: 5px;
        }
      }
      &.vacancy {
        background-image: url("../img/vacancy.jpg");
      }
      &.stud {
        background-image: url("../img/stud.jpg");
      }
      &.int {
        background-image: url("../img/int.jpg");
      }
    }
  }
}
.about_wrapper-career {
  h3 {
    font-family: $average_font;
    padding: 0 7%;
    color: $middle_font_color;
    font-size: 1.1em;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 768px) {
  .body_intro {
    height: 450px;
    padding: 0 30px;
    &.body_intro_career {
      .body_intro_container {
        min-width: 100%;
        box-sizing: border-box;
        padding: 0 20px;
        .body_intro_text {
          white-space: normal;
        }
        .body_intro_description {
          font-size: 18px;
        }
      }
    }
  }
  .thurd_line_opportunities {
    .body_offer {
      padding-top: 45px;

      .section_header {
        margin-bottom: 20px;
      }
      .section_text {
        font-size: 16px;
        margin-bottom: 25px;
        padding: 0;
      }
      .topmenu {
        &.career_topmenu {
          flex-direction: column;
          height: auto;
          .career_nav_item {
            height: 250px;
            margin: 0 0 30px;
            max-width: 100%;
            -webkit-filter: none;
            filter: none;
            span {
              width: calc(100% - 30px);
              padding: 5px 15px 5px 15px;
              left: 0;
              bottom: 10px;
              background-color: #f16c20;
            }
          }
        }
      }
    }
  }
}
